import React, { useContext, useEffect, useState } from 'react';
import {
    Box,
    Typography,
    Card,
    CardContent,
    Button,
    Container,
    useMediaQuery,
    useTheme,
    CircularProgress,
    Dialog,
} from "@mui/material";
import { useFirebase } from "contexts/Firebase";
import { useDocument } from "react-firebase-hooks/firestore";
import { FormattedNumber } from "react-intl";
import PlanSummary from './PlanSummary';
import CobraPlanSummary from './CobraPlanSummary';
import { useAuth } from 'contexts/User';
import { doc } from 'firebase/firestore';
import PreferencesModal from './PreferencesModal'
import { Lock } from "@mui/icons-material";
import { ModalContext } from 'contexts/Modal';
import { useNavigate } from 'react-router';
import { useRemoteConfig } from 'contexts/RemoteConfig';

function RecommendedPlans({ cobraCost, recommendedPlan, plansLoading }: { cobraCost?: number, recommendedPlan: any, plansLoading: boolean }) {
    const { firestore } = useFirebase();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [potentialSaving, setPotentialSaving] = useState<number>(0)

    const [openPreferences, setOpenPreferences] = useState<boolean>(false);
    const { modalDispatch } = useContext(ModalContext);
    const navigate = useNavigate();
    const FF_ideon = useRemoteConfig("ideon").asBoolean();

    const { user, answers } = useAuth();

    const [userCobraPlan, userCobraPlanLoading,] = useDocument(
        user
            ? doc(firestore, `users/${user?.uid}/settings/insurance`)
            : null,
        {
            snapshotListenOptions: { includeMetadataChanges: true },
        }
    );

    function goto(uri: string) {
        navigate(uri);
    }

    useEffect(() => {
        const planRates = recommendedPlan.map((plan: { rate: any; rateSubsidized: any; federalSubsidyAmount: any; }) => {
            let rate;

            if (FF_ideon) {
                rate = plan?.rateSubsidized || plan?.rate;
            } else {
                const federalSubsidyAmountQuotit = answers?.data()?.insuranceDetails?.federalSubsidyAmount || 0;
                rate = federalSubsidyAmountQuotit > 0 ? plan?.rate - federalSubsidyAmountQuotit : plan?.rate;
            }

            return rate;
        });
        if (cobraCost) {
            const adjustedRates = planRates.map((rate: number) => cobraCost - rate);
            const maxAdjustedRate = Math.max(...adjustedRates);
            setPotentialSaving(maxAdjustedRate);
        }
    }, [cobraCost, recommendedPlan, FF_ideon, answers]);

    return (
        <Card
            sx={{
                backgroundColor: "white.main",
                height: { xs: "auto", sm: "auto" },
                width: "100%",
            }}
        >
            <CardContent
                sx={{
                    textAlign: "left",
                    display: "flex",
                    flexDirection: "column",
                    gap: "33px",
                    padding: 0,
                }}
            >
                <Container>
                    <CobraPlanSummary userCobraPlanLoading={userCobraPlanLoading} plan={userCobraPlan?.data()} isMobile={isMobile} />
                    {plansLoading ?
                        <Box padding={2} justifyContent={'center'} display={'flex'}><CircularProgress color="primary" /> </Box> :
                        <>
                            {recommendedPlan.length ?
                                <>
                                    <Box sx={{ pt: 3 }}>
                                        <Typography variant="h2" fontWeight={600} color={"primary.main"} data-testid="recommendedPlan" >
                                            Recommended plans
                                        </Typography>
                                        {userCobraPlan?.data() && potentialSaving > 0 &&
                                            <Typography variant="h6" color="#000">
                                                Based on your answers, here’s your top 3 recommended plans that can help you <Box component='span' fontWeight={600}> save as much as</Box>
                                                <Box component="span" fontWeight={600} sx={{ m: 1 }}>
                                                    <FormattedNumber style={`currency`} currency="USD" value={potentialSaving || 0} />
                                                </Box>
                                            </Typography>
                                        }
                                    </Box>
                                    <PlanSummary cobraPlan={userCobraPlan?.data()} cobraCost={cobraCost} plan={recommendedPlan[0]} isMobile={isMobile} title="" ></PlanSummary>
                                    <PlanSummary cobraPlan={userCobraPlan?.data()} cobraCost={cobraCost} plan={recommendedPlan[1]} isMobile={isMobile} title="" ></PlanSummary>
                                    <PlanSummary cobraPlan={userCobraPlan?.data()} cobraCost={cobraCost} plan={recommendedPlan[2]} isMobile={isMobile} title="" ></PlanSummary>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 4, }} gap={2}>
                                        <Typography variant='h5'>Jamie found 320 Health Insurance options based on your preferences.
                                            <Button sx={{ fontSize: '16px', fontWeight: 'bold' }} variant="text" onClick={() => { setOpenPreferences(true) }}>
                                                Click here to update your preferences
                                            </Button>
                                        </Typography>
                                        <Button variant="outlined" sx={{ p: '5px 35px' }} onClick={() => {
                                            if (user?.isAnonymous) {
                                                modalDispatch({ type: "createAccount" })
                                            } else {
                                                goto("/account/insurance-plans")
                                            }
                                        }}>Browse All Plans  {user?.isAnonymous && <Lock sx={{ height: "16px", mt: "-4px" }} />} </Button>
                                    </Box>
                                </>
                                :
                                <Box>
                                    <Typography variant="h6" color={"primary.main"} data-testid="noRecommendedPlan" sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        paddingTop: 4,
                                    }}>
                                        Currently, no recommended plans available.
                                    </Typography> </Box>
                            }
                        </>

                    }
                </Container>
            </CardContent>
            <Dialog
                open={openPreferences}
                onClose={() => { setOpenPreferences(false); }}
                fullWidth
                maxWidth="lg"
                aria-labelledby="preferences-modal-title"
            >
                <PreferencesModal close={() => { setOpenPreferences(false); }}></PreferencesModal>
            </Dialog>
        </Card >
    );
}

export default RecommendedPlans;
