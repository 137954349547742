/** @jsxImportSource @emotion/react */
import { Typography, Link, Box, Grid, Container, Stack, Divider } from "@mui/material";
import { useDisclaimers } from "contexts/Disclaimers";
import { useSettings } from "contexts/Settings";
import moment from "moment";
import { useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";

function Footer() {
  const { disclaimers } = useDisclaimers();
  const { settings } = useSettings();
  const basePath = settings.REACT_APP_WHEN_URL
  useEffect(() => {
    console.log("footer disclaimers", disclaimers, basePath);
  })
  return (
    <Box sx={{
      backgroundColor: "primary.main",
      color: "white.main",
      marginTop: 3,
      paddingX: "2rem",
      paddingBottom: "2rem",
    }}>
      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={5} sx={{
            textAlign: "left",
          }}>
            <img src="/when-logo-white.png" width="128px" alt="When Logo"></img><br />
            <Typography variant="footer">Copyright {moment().format("YYYY")} When, Inc</Typography>
          </Grid>
          <Grid item xs={12} sm={7}>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <Link component={RouterLink} underline="none" color="#fff" to={`${basePath}/employers#how-it-works`}>How it Works</Link>
              </Grid>
              <Grid item xs={4}>
                <Link component={RouterLink} underline="none" color="#fff" to={`${basePath}/employers`}>For Employers</Link>
              </Grid>
              <Grid item xs={4}>
                <Link component={RouterLink} to={`${basePath}/privacy`} underline="none" color="#fff">Privacy Policy</Link>
              </Grid>
              <Grid item xs={4}>
                <Link component={RouterLink} to={`${basePath}/terms-of-service`} underline="none" color="#fff">Terms of Service</Link>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="footer">
                  <Box
                    onClick={() => { if (window.zE) { window.zE('messenger', 'open'); } }}
                    sx={{ display: 'inline', cursor: 'pointer', textUnderlineOffset: '3px' }}
                  >
                    Privacy & Data Inquiries
                  </Box>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Stack direction={"row"} spacing={1} flexWrap={"wrap"} divider={<Divider orientation="vertical" sx={{ backgroundColor: "white.main" }} flexItem />}>
              <Link component={RouterLink} to={`${basePath}/language-resources#chinese`} lang="zh" underline="none" color="#fff">中文</Link>
              <Link component={RouterLink} to={`${basePath}/language-resources#creole`} lang="ht" underline="none" color="#fff">Kreyòl</Link>
              <Link component={RouterLink} to={`${basePath}/language-resources#french`} lang="fr" underline="none" color="#fff">Français</Link>
              <Link component={RouterLink} to={`${basePath}/language-resources#german`} lang="de" underline="none" color="#fff">Deutsch</Link>
              <Link component={RouterLink} to={`${basePath}/language-resources#gujarati`} lang="gu" underline="none" color="#fff">ગુજરાતી</Link>
              <Link component={RouterLink} to={`${basePath}/language-resources#hindi`} lang="hi" underline="none" color="#fff">हिंदी</Link>
              <Link component={RouterLink} to={`${basePath}/language-resources#italian`} lang="it" underline="none" color="#fff">Italiano</Link>
              <Link component={RouterLink} to={`${basePath}/language-resources#japanese`} lang="ja" underline="none" color="#fff">日本語</Link>
              <Link component={RouterLink} to={`${basePath}/language-resources#korean`} lang="ko" underline="none" color="#fff">한국어</Link>
              <Link component={RouterLink} to={`${basePath}/language-resources#polish`} lang="pl" underline="none" color="#fff">Polski</Link>
              <Link component={RouterLink} to={`${basePath}/language-resources#portuguese`} lang="pt" underline="none" color="#fff">Português</Link>
              <Link component={RouterLink} to={`${basePath}/language-resources#russian`} lang="ru" underline="none" color="#fff">Русский</Link>
              <Link component={RouterLink} to={`${basePath}/language-resources#spanish`} lang="es" underline="none" color="#fff">Español</Link>
              <Link component={RouterLink} to={`${basePath}/language-resources#tagalog`} lang="tl" underline="none" color="#fff">Tagalog</Link>
              <Link component={RouterLink} to={`${basePath}/language-resources#vietnamese`} lang="vi" underline="none" color="#fff">tiếng việt</Link>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="footer">For additional language and interpreter services please contact us at <Link color="#fff" href={`tel:${settings.REACT_APP_CONCIERGE_PHONE}`}>{settings.REACT_APP_CONCIERGE_PHONE}</Link></Typography>
          </Grid>
        </Grid>
        <Box sx={{
          marginTop: 3,
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}>
          <Box>Plan Rating Methodology: Our plan rating is based purely on the inputs you can find in user preferences. All preferences are given equal weights. This takes into consideration your age, household, plan type preferences, and other preferences to generate a score. Our algorithm does not take into account potential commission, or carrier appointments</Box>
          <Box>Attention: This website is operated by When Insurance Agency, Inc. and is not the Health
            Insurance Marketplace website. In offering this website, When Insurance Agency, Inc. is required
            to comply with all applicable federal law, including the standards established under 45
            C.F.R. 155.220(c) and (d) and standards established under 45 C.F.R. 155.260 to protect
            the privacy and security of personally identifiable information. To see all available data on Qualified Health Plan
            options in your state, go to Health Insurance Marketplace website at <a style={{ color: "white" }} href="https://HealthCare.gov" target="_blank" rel="noreferrer">HealthCare.gov</a>.</Box>
          {/* {disclaimers.map((disclaimer: any, index: number) => {
            return (
              <Box key={`disclaimer-${index}`} dangerouslySetInnerHTML={{ __html: disclaimer }}></Box>
            )
          })} */}
        </Box>
      </Container>
    </Box>
  );
}
export default Footer;
