/** @jsxImportSource @emotion/react */
import { Typography, Box, Container, Button, Card, Chip, Tabs, Tab, Select, MenuItem } from "@mui/material";
import { useEffect, useState } from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import { collection, orderBy, query, QuerySnapshot } from "firebase/firestore";
import { DataGrid } from "@mui/x-data-grid";
import { DataPipelineConverter } from "services/Firestore";
import { DataPipeline } from "services/Interfaces";
import moment from "moment";
import { useFirebase } from "contexts/Firebase";
import { useMutation } from "@apollo/client";
import { Mutations } from "services/apollo";

function DataPipelines() {
  const { firestore } = useFirebase();
  const [tabValue, setTabValue] = useState("all");

  const [pipelines, ,] = useCollection(
    query(collection(firestore, "dataPipelines").withConverter(DataPipelineConverter),
      orderBy("startTime", "desc")
    ),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );
  const [triggerPipeline] = useMutation(Mutations.triggerPipeline);
  const refreshManualPipelines = async () => {
    // call admin-graphql to send pubsub
    triggerPipeline({ variables: { type: "manual-pipelines" } })
  }
  const [manualPipelines, ,] = useCollection(
    query(collection(firestore, "manualPipelines"),
    ),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );
  const pipelineColumns = [
    { field: 'name', headerName: 'Name', width: 400 },
    {
      field: 'time', headerName: 'Time', width: 200, renderCell: (params: any) => {
        return (
          <Typography>
            {params.row.endTime ? moment(params.row.endTime).format("MM/DD/YYYY hh:mm A") : moment(params.row.startTime).format("MM/DD/YYYY hh:mm A")}
          </Typography>
        )
      }
    },
    {
      field: "status",
      headerName: "Status",
      width: 200,
      renderCell: (params: any) => {
        if (params.row.endTime && !params.row.errors) {
          return <Chip label="Success" color="success" />
        } else if (params.row.endTime && params.row.errors) {
          return <Chip label="Error" color="error" />
        } else {
          return <Chip label="Running" color="info" />
        }
      }
    },
    { field: 'type', headerName: 'Type', width: 200 },
  ]

  return (
    <Container sx={{
      textAlign: "left",
      display: "flex",
      flexDirection: "column",
      gap: "20px",
      pt: "20px",
    }}>
      <Typography variant="h2" component="h1">Data Pipelines</Typography>
      <Tabs value={tabValue} onChange={(e, newValue) => setTabValue(newValue)}>
        <Tab label="All" value="all" />
        {
          manualPipelines?.docs.map(manualPipeline => manualPipeline.data()).map(manualPipeline => {
            return <Tab key={manualPipeline.type} label={manualPipeline.name} value={manualPipeline.type} />
          })
        }
        <Tab label="Refresh" value="refresh" />
      </Tabs>
      <Box
        sx={{
          display: tabValue === "all" ? "flex" : "none",
          flexDirection: "column",
          gap: "40px",
          pt: "20px",
        }}>
        <Box>
          <Card>
            <DataGrid
              autoHeight
              columns={pipelineColumns}
              rows={pipelines?.docs
                ?.map(company => { return company.data() }) || []
              }
            ></DataGrid>
          </Card>
        </Box>
      </Box>
      <Box
        sx={{
          display: tabValue !== "refresh" && tabValue !== "all" ? "flex" : "none",
          flexDirection: "column",
          gap: "40px",
          pt: "20px",
        }}>
        <ManualPipeline manualPipeline={manualPipelines?.docs
          ?.map(pipeline => { return pipeline.data() })
          .find((manualPipeline: any) => manualPipeline.type === tabValue)} dataPipelines={pipelines} />
      </Box>
      <Box
        sx={{
          display: tabValue === "refresh" ? "flex" : "none",
          flexDirection: "column",
          gap: "40px",
          pt: "20px",
        }}>
        <Box>
          <Card>
            <Button onClick={refreshManualPipelines}>Refresh Manual Pipelines</Button>
          </Card>
        </Box>
      </Box>
    </Container >
  );
}
function ManualPipeline({ manualPipeline = {}, dataPipelines }: { manualPipeline: any, dataPipelines?: QuerySnapshot<DataPipeline> }) {
  const [triggerPipeline] = useMutation(Mutations.triggerPipeline);
  const parameters = manualPipeline.parameters;
  const type = manualPipeline.type;
  const name = manualPipeline.name;
  const associatedPipelines = manualPipeline.associatedPipelines;
  const [params, setParams] = useState(parameters ? Object.keys(parameters).reduce((acc: { [key: string]: any }, key: string) => {
    acc[key] = parameters[key].default || "";
    return acc;
  }, {}) : {});
  const pipelineColumns = [
    { field: 'name', headerName: 'Name', width: 400 },
    {
      field: 'time', headerName: 'Time', width: 200, renderCell: (params: any) => {
        return (
          <Typography>
            {params.row.endTime ? moment(params.row.endTime).format("MM/DD/YYYY hh:mm A") : moment(params.row.startTime).format("MM/DD/YYYY hh:mm A")}
          </Typography>
        )
      }
    },
    {
      field: "status",
      headerName: "Status",
      width: 200,
      renderCell: (params: any) => {
        if (params.row.endTime && !params.row.errors) {
          return <Chip label="Success" color="success" />
        } else if (params.row.endTime && params.row.errors) {
          return <Chip label="Error" color="error" />
        } else {
          return <Chip label="Running" color="info" />
        }
      }
    },
    { field: 'type', headerName: 'Type', width: 200 },
  ]

  console.log("pipeline", manualPipeline);
  if (!manualPipeline || !associatedPipelines) {
    return null;
  }
  const setParameters = (key: string, value: any) => {
    setParams({ ...params, [key]: value });
  }
  const submitTrigger = async () => {
    // call admin-graphql to send pubsub
    console.log("triggering pipeline", type, params);
    triggerPipeline({ variables: { type: type, parameters: JSON.stringify(params) } })
  }
  return (
    <Box sx={{
      display: "flex",
      flexDirection: "column",
      gap: "20px",
    }}>
      <Typography variant="h4" component="h3">{name}</Typography>
      <Card sx={{
        p: 2,
        display: "flex",
        flexDirection: "column",
        gap: "20px",
      }}>
        <Typography>Manual Trigger</Typography>
        <Typography>Parameters</Typography>
        <Box sx={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}>
          {
            Object.keys(parameters).map(key => {
              const param = parameters[key];
              let shouldShow = true;
              if (param.showFor) {
                shouldShow = false;
                Object.keys(param.showFor).forEach(showFor => {
                  if (param.showFor[showFor].split("|").includes(params[showFor])) {
                    shouldShow = true;
                  }
                })
              }
              if (!shouldShow) {
                return null;
              }
              if (param.type === "enum") {
                return (
                  <Box key={key} sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "20px",
                  }}>
                    <Typography>{key}</Typography>
                    <Select placeholder={key} value={params[key] || ""} onChange={(e) => setParameters(key, e.target.value)}>
                      {
                        param.enum.split("|").map((value: string) => {
                          return <MenuItem key={value} value={value}>{value}</MenuItem>
                        })
                      }
                    </Select>
                  </Box>
                )
              }
              if (param.type === "lookup") {
                return (
                  <Box key={key} sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "20px",
                  }}>
                    <Typography>{key}</Typography>
                    {param.lookup === "companyId" && <LookupCompanySelector specialOptions={param.specialOptions} onChange={(value) => setParameters(key, value)} />}
                  </Box>
                )
              }
              return (
                <Box key={key} sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "20px",
                }}>
                  <Typography>{key}</Typography>
                  <Typography>{JSON.stringify(parameters[key])}</Typography>
                </Box>
              )
            })
          }
        </Box>
        <Button onClick={submitTrigger}>Trigger</Button>
      </Card>
      <Card>
        <DataGrid
          autoHeight
          columns={pipelineColumns}
          rows={dataPipelines?.docs
            ?.map(pipeline => { return pipeline.data() })
            ?.filter(pipeline => associatedPipelines.includes(pipeline.type))
            || []
          }
        ></DataGrid>
      </Card>
    </Box>
  )
}
function LookupCompanySelector({ onChange, specialOptions }: { onChange: (value: string) => void, specialOptions?: { value: string, label: string }[] }) {
  const { firestore } = useFirebase();
  const [selected, setSelected] = useState(specialOptions ? specialOptions[0].value : "");

  useEffect(() => {

    if (specialOptions && specialOptions.length > 0) {
      setSelected(specialOptions[0].value);
    }
  }, [specialOptions])
  // get companies from firestore
  const [companies] = useCollection(query(collection(firestore, "companies")));
  return (
    <Select displayEmpty value={selected} onChange={(e) => {
      setSelected(e.target.value as string);
      onChange(e.target.value as string);
    }}>
      {
        specialOptions?.map(option => {
          return <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
        })
      }
      {
        companies?.docs.map(company => {
          return <MenuItem key={company.id} value={company.id}>{company.data().name}</MenuItem>
        })
      }
    </Select>
  )
}
export default DataPipelines;
