import React from 'react';
import { Box, Typography, Select, MenuItem, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Dialog, FormControl, InputLabel } from "@mui/material";
import { SelectChangeEvent } from '@mui/material/Select';
import type { FC, ReactElement } from 'react';
import PropTypes from 'prop-types';

interface PremiumTier {
    employeeContribution: number;
    employerContribution: number;
    totalPremium: number;
}

interface FourTierPremium {
    employeeOnly: PremiumTier;
    employeePlusChildren: PremiumTier;
    employeePlusSpouse: PremiumTier;
    family: PremiumTier;
}

interface BenefitPlan {
    id: string;
    name: string;
    carrier: string;
    planNetworkType: string;
    individualDeductible: number;
    familyDeductible: number;
    individualOutOfPocketMaximum: number;
    familyOutOfPocketMaximum: number;
    fourTierPremium: FourTierPremium;
}

interface PlanSelectionDialogProps {
    open: boolean;
    onClose: () => void;
    selectedPlan: string;
    onPlanSelect: (planId: string) => void;
    onConfirm: () => void;
    benefitPlans: BenefitPlan[];
    household: {
        [key: string]: {
            name: string;
            relationship: string;
            dob: string;
            gender: string;
        }
    } | null | undefined;
}

interface PlanDetailsTableProps {
    selectedPlanDetails: BenefitPlan;
    premiumTier: keyof FourTierPremium;
}

const householdValidator = PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.object,
    PropTypes.oneOf([null, undefined])
]);

const premiumTierShape = PropTypes.shape({
    employeeContribution: PropTypes.number.isRequired,
    employerContribution: PropTypes.number.isRequired,
    totalPremium: PropTypes.number.isRequired,
});

const benefitPlanShape = PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    carrier: PropTypes.string.isRequired,
    planNetworkType: PropTypes.string.isRequired,
    individualDeductible: PropTypes.number.isRequired,
    familyDeductible: PropTypes.number.isRequired,
    individualOutOfPocketMaximum: PropTypes.number.isRequired,
    familyOutOfPocketMaximum: PropTypes.number.isRequired,
    fourTierPremium: PropTypes.shape({
        employeeOnly: premiumTierShape.isRequired,
        employeePlusChildren: premiumTierShape.isRequired,
        employeePlusSpouse: premiumTierShape.isRequired,
        family: premiumTierShape.isRequired,
    }).isRequired,
});

// Move these outside the component to prevent recreation
const determinePremiumTier = (members: any): keyof FourTierPremium => {
    if (!members) return 'employeeOnly';
    
    const memberCount = Object.keys(members).length;
    const hasSpouse = Object.values(members).some((member: any) => member.relationship === 'spouse');
    const hasChildren = Object.values(members).some((member: any) => member.relationship === 'child');

    if (memberCount === 1) return 'employeeOnly';
    if (hasSpouse && hasChildren) return 'family';
    if (hasSpouse) return 'employeePlusSpouse';
    if (hasChildren) return 'employeePlusChildren';
    return 'employeeOnly';
};

// Create a memoized table component
const PlanDetailsTable: FC<PlanDetailsTableProps> = React.memo(({ selectedPlanDetails, premiumTier }) => (
    <TableContainer component={Paper} sx={{ mb: 3 }}>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell sx={{ fontWeight: 'bold', backgroundColor: 'background.paper' }}>
                        Detail
                    </TableCell>
                    <TableCell sx={{ fontWeight: 'bold', backgroundColor: 'background.paper' }}>
                        Value
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    <TableCell component="th" scope="row">Monthly Premium</TableCell>
                    <TableCell>${selectedPlanDetails.fourTierPremium[premiumTier].totalPremium}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row">Your Contribution</TableCell>
                    <TableCell>${selectedPlanDetails.fourTierPremium[premiumTier].employeeContribution}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row">Employer Contribution</TableCell>
                    <TableCell>${selectedPlanDetails.fourTierPremium[premiumTier].employerContribution}</TableCell>
                </TableRow>
                <TableRow sx={{ backgroundColor: 'background.paper' }}>
                    <TableCell colSpan={2} sx={{ fontWeight: 'bold' }}>
                        Individual Coverage
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row">Individual Deductible</TableCell>
                    <TableCell>${selectedPlanDetails.individualDeductible}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row">Individual Out-of-Pocket Maximum</TableCell>
                    <TableCell>${selectedPlanDetails.individualOutOfPocketMaximum}</TableCell>
                </TableRow>
                <TableRow sx={{ backgroundColor: 'background.paper' }}>
                    <TableCell colSpan={2} sx={{ fontWeight: 'bold' }}>
                        Family Coverage
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row">Family Deductible</TableCell>
                    <TableCell>${selectedPlanDetails.familyDeductible}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row">Family Out-of-Pocket Maximum</TableCell>
                    <TableCell>${selectedPlanDetails.familyOutOfPocketMaximum}</TableCell>
                </TableRow>
            </TableBody>
        </Table>
    </TableContainer>
));

PlanDetailsTable.propTypes = {
    selectedPlanDetails: benefitPlanShape.isRequired,
    premiumTier: PropTypes.oneOf<keyof FourTierPremium>(['employeeOnly', 'employeePlusChildren', 'employeePlusSpouse', 'family']).isRequired,
};

PlanDetailsTable.displayName = 'PlanDetailsTable';

const PlanSelectionDialog: FC<PlanSelectionDialogProps> = React.memo(({
    open,
    onClose,
    selectedPlan,
    onPlanSelect,
    onConfirm,
    benefitPlans,
    household
}): ReactElement => {
    // Memoize selected plan details
    const selectedPlanDetails = React.useMemo(() => 
        benefitPlans.find(plan => plan.id === selectedPlan),
        [benefitPlans, selectedPlan]
    );

    // Memoize premium tier calculation
    const premiumTier = React.useMemo(() => 
        determinePremiumTier(household),
        [household]
    );

    // Memoize handler
    const handlePlanChange = React.useCallback((event: SelectChangeEvent<string>) => {
        onPlanSelect(event.target.value);
    }, [onPlanSelect]);

    // Memoize the plan options
    const planOptions = React.useMemo(() => 
        benefitPlans.map((plan) => (
            <MenuItem key={plan.id} value={plan.id}>
                {plan.name} - {plan.carrier} ({plan.planNetworkType})
            </MenuItem>
        )),
        [benefitPlans]
    );

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth="sm"
            TransitionProps={{
                mountOnEnter: true,
                unmountOnExit: true
            }}
        >
            <Box sx={{ p: 4 }}>
                <Typography variant="h4" sx={{ mb: 3 }}>
                    Select Your Benefit Plan
                </Typography>
                <Typography variant="h6" sx={{ mb: 4 }}>
                Our records indicate that you will be offered COBRA.
                </Typography>
                <Typography variant="h6" sx={{ mb: 4 }}>
                Please confirm the plan you were enrolled in and for which you will be offered COBRA.
                </Typography>
                <Typography variant="h6" sx={{ mb: 4 }}>
                We will compare this plan against the other plans available to you.
                </Typography>
                <FormControl fullWidth sx={{ mb: 3 }}>
                    <InputLabel>Benefit Plan</InputLabel>
                    <Select
                        value={selectedPlan}
                        onChange={handlePlanChange}
                        label="Benefit Plan"
                    >
                        {planOptions}
                    </Select>
                </FormControl>
                {selectedPlanDetails && (
                    <PlanDetailsTable 
                        selectedPlanDetails={selectedPlanDetails}
                        premiumTier={premiumTier}
                    />
                )}
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
                    <Button onClick={onClose}>
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        disabled={!selectedPlan}
                        onClick={onConfirm}
                    >
                        Confirm
                    </Button>
                </Box>
            </Box>
        </Dialog>
    );
});

PlanSelectionDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    selectedPlan: PropTypes.string.isRequired,
    onPlanSelect: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    benefitPlans: PropTypes.arrayOf(benefitPlanShape).isRequired,
    household: householdValidator
} as any;

PlanSelectionDialog.displayName = 'PlanSelectionDialog';

export default PlanSelectionDialog;