/** @jsxImportSource @emotion/react */
import { Box, TextField, Button, Typography, Grid, Container, Dialog, Divider, ButtonBase, LinearProgress, InputAdornment, IconButton, FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import { useState, useContext, useEffect } from "react";
import React from "react";
import { useAuth } from "../../contexts/User";
import { useLocation, useNavigate } from "react-router-dom";
import { ModalContext } from "../../contexts/Modal";
import mixpanel from 'mixpanel-browser';
import ForgotPassword from "./ForgotPassword";
import { doc } from "@firebase/firestore";
import { useDocument } from "react-firebase-hooks/firestore";
import signInWithGoogleImage from "./btn_google_signin_light_normal_web.png";
import { UserActivityTypes } from "services/Interfaces";
import { addDoc, collection } from "firebase/firestore";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useFirebase } from "contexts/Firebase";
import MuiPhoneNumber from "mui-phone-number";
import { phonePattern } from "services/validation";

function CreateAccount(props: { redirect?: string, specifyMessage?: boolean, landingPage?: boolean }) {
  const { firestore } = useFirebase();
  let navigate = useNavigate();
  const { user, authed, signup, signInWithGoogle, loading } = useAuth();
  const userAnswersDoc = user ? doc(firestore, 'users', user?.uid!, "answers", user?.uid!) : null;
  const [answers, answersLoading, firestoreError] = useDocument(
    userAnswersDoc,
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  const [forgotPassword, setForgotPassword] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordStrength, setPasswordSStrength] = useState(0)
  const [error, setError] = useState<string>("");
  const [loginLoading, setLoading] = useState<boolean>(false);
  const [acceptPrivacyPolicy, setAcceptPrivacyPolicy] = useState<boolean>(false);
  const [acceptTermsOfService, setAcceptTermsOfService] = useState<boolean>(false);
  const onEmailChange = (e: any) => setEmail(e.target.value);
  const { modalState, modalDispatch } = useContext(ModalContext);
  const location = useLocation();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };
  interface CustomizedState {
    path: string
  }
  const onPasswordChange = (e: any) => {
    const password = e.target.value;
    const lengthCheck = /.{8,}/.test(password);
    const numberCheck = /\d/.test(password);
    const symbolCheck = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    const uppercaseCheck = /[A-Z]/.test(password);
    const lowercaseCheck = /[a-z]/.test(password);

    const checksPassed = [lengthCheck, numberCheck, symbolCheck, uppercaseCheck, lowercaseCheck].filter(Boolean).length;
    const percentage = checksPassed * 20;
    setPasswordSStrength(percentage)
    setPassword(e.target.value)
    if (percentage === 100) {
      setError("")
    } else {
      setError("Please enter a password that has uppercase letter, lowercase letter, number, symbol & minimum 8 characters.")
    }
  };
  useEffect(() => {
    console.log(passwordStrength)
  }, [passwordStrength])
  function afterSignUp() {
    mixpanel.track("Create Account");
    //go to dashboard
    if (props.redirect) {
      const state = location.state as CustomizedState; // Type Casting, then you can get the params passed via router
      console.log("signup path redirect", state?.path);
      navigate(props.redirect)
    }
    // navigate('/__/auth/action', { replace: true });
    //if modal is open, close it
    if (modalState.createAccount) {
      modalDispatch({ type: 'createAccount' });
    }
  }
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError("");
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email.trim() === "") {
      setError("Please enter your email");
      return;
    } else if (!emailRegex.test(email)) {
      setError("Please enter a valid email address");
      return;
    }
    if (firstName === "") {
      setError("Please enter your first name");
      return;
    }
    if (lastName === "") {
      setError("Please enter your last name");
      return;
    }
    if (props.landingPage && !phonePattern.test(phone)) {
      setError("Please enter a valid phone number");
      return;
    }
    if (password === "") {
      setError("Please enter your password");
      return;
    }
    if (passwordStrength !== 100) {
      setError("Please enter a password that has uppercase letter, lowercase letter, number, symbol & minimum 8 characters.")
      return;
    }
    if (password !== confirmPassword) {
      setError("The passwords do not match.")
      return;
    }
    if (!acceptPrivacyPolicy) {
      setError("Please accept our privacy policy");
      return;
    }
    if (!acceptTermsOfService) {
      setError("Please accept our Terms of service");
      return;
    }
    setLoading(true);
    signup(email, password, firstName, lastName, phone).then(async (user) => {
      afterSignUp();
      const userActivityCollectionRef = collection(firestore, `users/${user.uid}/activity`);
      await addDoc(userActivityCollectionRef, {
        activity: UserActivityTypes.REGISTERED,
        type: "user",
        userId: user.uid,
        timestamp: new Date(),
      });
    }).catch(err => {
      const errorCode = err.code;
      if (errorCode === 'auth/wrong-password' || errorCode === 'auth/user-not-found' || errorCode === 'auth/invalid-email') {
        setError("Invalid email or password");
      } if (errorCode === 'auth/too-many-requests') {
        setError("Too many login attempts, your account is temporarily locked. Please try again later");
      } else {
        setError("An Error Occurred, Please try again later");
      }
      console.log(err);
      setLoading(false);
    });
  };
  useEffect(() => {
    if (user && user.isAnonymous && !answersLoading && !firestoreError) {
      setEmail(answers?.get("personalInfo.email"));
    }
  }, [answers, answersLoading, firestoreError, user])
  if (loading) {
    return (
      <Box></Box>
    );
  }
  if (authed) {
    if (props.redirect) {
      navigate(props.redirect)
    }
    return (
      <Box sx={{
        display: authed ? "flex" : "none",
      }}>You are already logged in</Box>);
  }
  return (
    <Container id="createAccountForm" maxWidth="md" sx={{
      textAlign: "center",
      justifyContent: "center",
      pb: { xs: 4 },
      py: 2
    }}
    data-testid="createAccountModal"
    >
      {/* <Typography variant="body2">Protecting Your Access to Care</Typography> */}
      <Typography variant="h4" sx={{
        paddingBottom: "2rem",
      }}>Create your When account</Typography>
      <Grid container spacing={6}>

        <Grid item xs={12} sm={12}>
          <form onSubmit={handleSubmit}>
            <Box sx={{
              display: authed ? "none" : "flex",
              flexDirection: "column",
              gap: "1rem",
            }}>
              <TextField
                id="firstName"
                color="secondary"
                sx={{ borderRadius: "10px" }}
                label="First Name"
                onChange={(e) => { setFirstName(e.target.value) }}
                value={firstName}
                required />
              <TextField
                id="lastName"
                color="secondary"
                sx={{ borderRadius: "10px" }}
                label="Last Name"
                onChange={(e) => { setLastName(e.target.value) }}
                value={lastName}
                required />
              <TextField
                id="email"
                color="secondary"
                sx={{ borderRadius: "10px" }}
                label={props.specifyMessage ? "Email Address (Personal)" : "Email Address"}
                helperText={props.specifyMessage ? "Please use your personal email address to create your account." : ""}
                onChange={onEmailChange}
                /* onChange={(e) => { handleChangeEmail(e) }} */
                value={email}
                required />
              {props.landingPage && <MuiPhoneNumber
                autoFormat={false}
                onChange={(value) => { setPhone(value as string) }}
                variant="outlined"
                onlyCountries={['us']}
                defaultCountry={'us'}
                disableAreaCodes={true}
                label="Phone Number"
                countryCodeEditable={false}
                value={phone}
                required
              />}
              <TextField
                id="password"
                label="Password"
                type={showPassword ? 'text' : 'password'}
                onChange={onPasswordChange}
                value={password}
                required
                variant="outlined"
                color="secondary"
                sx={{ borderRadius: "10px" }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                id="password"
                label="Confirm Password"
                type={showConfirmPassword ? 'text' : 'password'}
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                  if (password !== e.target.value) {
                    setError("The passwords do not match.")
                  }
                  else {
                    setError("")
                  }
                }}
                value={confirmPassword}
                required
                variant="outlined"
                color="secondary"
                sx={{ borderRadius: "10px" }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => { setShowConfirmPassword(!showConfirmPassword) }}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {/* <Typography variant="caption" color="error" >{error}</Typography> */}
              <Typography>Password Stregth: {passwordStrength === 100 && "strong"}
                {(passwordStrength >= 60 && passwordStrength < 100) && "medium"}
                {(passwordStrength >= 0 && passwordStrength < 60) && "low"}
              </Typography>
              <LinearProgress variant="determinate" sx={{ height: '8px' }} value={passwordStrength} />
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox checked={acceptPrivacyPolicy} onChange={(e) => setAcceptPrivacyPolicy(e.target.checked)} />}
                  label={
                    <Typography>
                      I have read and agree to the{' '}
                      <a href="https://www.forwhen.com/privacy" style={{ color: 'inherit', cursor: 'pointer' }}>
                        Privacy Policy
                      </a>
                    </Typography>
                  }
                />
                <FormControlLabel
                  control={<Checkbox checked={acceptTermsOfService} onChange={(e) => setAcceptTermsOfService(e.target.checked)} />}
                  label={
                    <Typography>
                      I have read and agree to the{' '}
                      <a href="https://www.forwhen.com/terms-of-service" style={{ color: 'inherit', cursor: 'pointer' }}>
                        Terms of Service
                      </a>
                    </Typography>
                  }
                />
              </FormGroup>
              <Typography variant="caption" color="error" >{error}</Typography>
              <Box>
                <Button variant="contained"
                  type="submit"
                  sx={{
                    maxWidth: { sm: "220px" }
                  }}
                  color="secondary"
                  disabled={loginLoading}>Create Account</Button>
              </Box>
              <Divider />
              <Box>
                <ButtonBase onClick={() => {
                  signInWithGoogle(email, true).then(() => {
                    afterSignUp();
                  }).catch(err => {
                    if (err.code === "auth/credential-already-in-use") {
                      setError("You already have an account, login instead");
                    } else {
                      setError("Error connecting your account to Google")
                    }
                  })
                }}>
                  <img src={signInWithGoogleImage} alt="Sign in with Google" />
                </ButtonBase>
              </Box>
            </Box>
          </form>
        </Grid>
      </Grid>
      <Dialog open={forgotPassword} onClose={() => setForgotPassword(false)}>
        <ForgotPassword close={() => { setForgotPassword(false) }} />
      </Dialog>
    </Container>
  );
}
export default CreateAccount;
