import React, { useEffect } from 'react';
import { Box, Button, Typography } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import ChatIcon from '@mui/icons-material/Chat';

interface HumanHandoffProps {
    sendPrompt?: (message: string) => void;
}

const HumanHandoff: React.FC<HumanHandoffProps> = () => {
    // Contact information - replace with actual values
    const contactInfo = {
        phone: '1-831-228-6412',
        email: 'jamie@forwhen.com'
    };

    // Hide the Zendesk messenger on component mount
    useEffect(() => {
        console.log('Checking for Zendesk on mount:', { zE: window.zE });
        if (window.zE) {
            console.log('Attempting to hide Zendesk messenger');
            try {
                window.zE('messenger', 'hide');
                console.log('Successfully hid Zendesk messenger');
            } catch (error) {
                console.error('Error hiding Zendesk messenger:', error);
            }
        } else {
            console.warn('Zendesk not initialized (window.zE not found)');
        }
    }, []);

    const handleZendeskOpen = () => {
        console.log('Chat button clicked, checking for Zendesk:', { zE: window.zE });
        if (window.zE) {
            console.log('Attempting to open Zendesk messenger');
            try {
                window.zE('messenger', 'open');
                console.log('Successfully triggered Zendesk open command');
            } catch (error) {
                console.error('Error opening Zendesk messenger:', error);
            }
        } else {
            console.error('Zendesk not initialized (window.zE not found)');
            // Fallback for testing - log what the Zendesk call would have been
            console.log('Would have called: window.zE("messenger", "open")');
        }
    };

    useEffect(() => {
        // Log Zendesk status every few seconds for debugging
        const checkInterval = setInterval(() => {
            console.log('Periodic Zendesk check:', {
                zEExists: !!window.zE,
                zEType: typeof window.zE,
                windowKeys: Object.keys(window).filter(key => key.includes('zE')),
            });
        }, 5000);

        return () => clearInterval(checkInterval);
    }, []);

    return (
        <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column',
            gap: 3,
            backgroundColor: 'background.paper',
            padding: 4,
            borderRadius: 1,
            border: '1px solid',
            borderColor: 'divider',
            maxWidth: '400px'
        }}>
            <Typography variant="h6" sx={{ mb: 2 }}>
                Need help? Contact our support team
            </Typography>

            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <PhoneIcon sx={{ color: 'text.secondary' }} />
                <Box>
                    <Typography variant="body2" color="text.secondary">
                        Call us at
                    </Typography>
                    <Typography variant="body1">
                        {contactInfo.phone}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        Available M-F 9AM-7PM CT
                    </Typography>
                </Box>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <EmailIcon sx={{ color: 'text.secondary' }} />
                <Box>
                    <Typography variant="body2" color="text.secondary">
                        Email us at
                    </Typography>
                    <Typography variant="body1">
                        {contactInfo.email}
                    </Typography>
                </Box>
            </Box>

            <Button
                variant="contained"
                color="primary"
                startIcon={<ChatIcon />}
                onClick={handleZendeskOpen}
                sx={{ mt: 2 }}
                data-testid="open-chat-button"
            >
                Chat with Support
            </Button>

            {/* Debug info visible during development */}
            {/* {process.env.NODE_ENV === 'development' && (
                <Box sx={{ mt: 2, p: 2, backgroundColor: '#f5f5f5', borderRadius: 1, fontSize: '12px' }}>
                    <Typography variant="caption">Debug Info:</Typography>
                    <pre>
                        {JSON.stringify({
                            zEExists: !!window.zE,
                            zEType: typeof window.zE,
                            windowKeys: Object.keys(window).filter(key => key.includes('zE')),
                        }, null, 2)}
                    </pre>
                </Box>
            )} */}
        </Box>
    );
};

// Add TypeScript global declaration for Zendesk
declare global {
    interface Window {
        zE: any;
    }
}

export default HumanHandoff;