/** @jsxImportSource @emotion/react */
import { Debug, AdminNavBar } from "./";
import Routing from "../../Routing";
import { Box, Typography, Modal, Paper, IconButton, Dialog, Button, Drawer, useMediaQuery, useTheme } from "@mui/material";
import { LoginForm } from "../User";
import { useAuth } from "../../contexts/User";
import { Suspense, useContext, useEffect } from "react";
import { ModalContext } from "../../contexts/Modal";
import { useRemoteConfigLoading, useRemoteConfig } from 'contexts/RemoteConfig';
import { Business, Close, CloseOutlined, Home, Person, Security, Storefront, Work, IntegrationInstructions, ListAlt, VerifiedUser, ManageHistory } from "@mui/icons-material";

import CreateAccount from "components/User/CreateAccount";
import { useNavigate } from "react-router-dom";
import { ReactComponent as JamieLogo } from "images/Jamie.svg";

const drawerWidth = "260px";
function CompanyContent() {
  const navigate = useNavigate();
  const { modalState, modalDispatch } = useContext(ModalContext);
  const whenEnrollmentFlag = useRemoteConfig("whenEnrollment").asBoolean() || false;
  const { loading, user, hasMFA } = useAuth();
  const debug = useRemoteConfig('debug').asBoolean();
  const remoteConfigLoading = useRemoteConfigLoading();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('lg'));

  useEffect(() => {
    //if query param debug is set, set debug to true
    // if (window.location.search.includes("debug")) {

    // }
    console.log("Content Rewrite", loading, user?.uid, remoteConfigLoading);
  }, [loading, user, remoteConfigLoading]);
  if (loading || remoteConfigLoading) {
    console.log("company CONTENT LOADING SCREEN", loading, remoteConfigLoading);
    return (
      <Box sx={{
      }}>
        <Typography>Loading</Typography>
        {!loading && <Routing />}
      </Box>
    );
  }
  if (!hasMFA) {
    return (
      <Box sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh"
      }}>
        <Typography variant="h3">Please enable MFA</Typography>
        <Button onClick={() => { navigate("/account/My-Account") }}>Account Settings</Button>
      </Box>
    )
  }
  console.log("company CONTENT", user)
  return (
    <Box sx={{ display: "flex" }}>

      <Drawer

        open={modalState.navigation}
        onClose={() => { modalDispatch({ type: "navigation" }) }}
        variant={!isMd ? "temporary" : "permanent"}
        sx={{
          "& .MuiDrawer-paper": {
            borderWidth: 0,
            boxSizing: 'border-box',
            width: drawerWidth,
            backgroundColor: "primary.main",
          },
          position: "relative",
          width: drawerWidth,
        }}>
        <Box sx={{
          width: drawerWidth,
          minHeight: "100vh",
          height: "auto",
          backgroundColor: "primary.main",
          display: "flex",
          flexDirection: "column",
          px: 2,
          py: 2,
          gap: 0.5,
          '&::-webkit-scrollbar': {
            width: '2.4em'
          },
          '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.1)',
            outline: '1px solid slategrey'
          }
        }}>
          {!isMd &&
            <IconButton sx={{
              position: "absolute",
              top: 2,
              right: 8,
              zIndex: 100
            }}
              onClick={() => { modalDispatch({ type: "navigation" }) }}
              id="nav-close"
            >
              <Close />
            </IconButton>
          }
          <Box sx={{
            pt: 1,
            pb: 2
          }}>
            <img id="main-logo" src="/when-logo-reversed.png" width="120px" alt="When" />
          </Box>
          <Typography sx={{ paddingLeft: "20px", color: 'redOrange.main' }} variant="h3" color="primary">ADMIN AREA</Typography>
          {/* <Typography sx={{ mb: 2, borderBottom: "1px solid", borderColor: 'primary.main' }} color={"white.main"} variant="h4" align="left">{company.get("name")}</Typography> */}
          <Button startIcon={<Home sx={{ height: 16 }} />} color="white" variant={window.location.pathname === "/admin" ? "contained" : undefined} sx={{ justifyContent: "start", background: window.location.pathname === "/admin" ? `linear-gradient(-72.47deg,rgb(255,145,0) 22.16%,rgba(255,145,0,.7) 88.47%)!important` : undefined, color: "white.main", borderRadius: 40, pl: 8, ml: -6 }} size="large" onClick={() => { navigate("/admin") }}>Admin Dashboard</Button>
          <Button startIcon={<Person sx={{ height: 16 }} />} color="white" variant={window.location.pathname.startsWith("/admin/users") ? "contained" : undefined} sx={{ justifyContent: "start", background: window.location.pathname.startsWith("/admin/users") ? `linear-gradient(-72.47deg,rgb(255,145,0) 22.16%,rgba(255,145,0,.7) 88.47%)!important` : undefined, color: "white.main", borderRadius: 40, pl: 8, ml: -6 }} size="large" onClick={() => { navigate("/admin/users") }}>Users</Button>
          <Button startIcon={<Business sx={{ height: 16 }} />} color="white" variant={window.location.pathname.startsWith("/admin/companies") ? "contained" : undefined} sx={{ justifyContent: "start", background: window.location.pathname.startsWith("/admin/companies") ? `linear-gradient(-72.47deg,rgb(255,145,0) 22.16%,rgba(255,145,0,.7) 88.47%)!important` : undefined, color: "white.main", borderRadius: 40, pl: 8, ml: -6 }} size="large" onClick={() => { navigate("/admin/companies") }}>Companies</Button>
          <Button startIcon={<Security sx={{ height: 16 }} />} color="white" variant={window.location.pathname.startsWith("/admin/claims") ? "contained" : undefined} sx={{ justifyContent: "start", background: window.location.pathname.startsWith("/admin/claims") ? `linear-gradient(-72.47deg,rgb(255,145,0) 22.16%,rgba(255,145,0,.7) 88.47%)!important` : undefined, color: "white.main", borderRadius: 40, pl: 8, ml: -6 }} size="large" onClick={() => { navigate("/admin/claims") }}>Claims</Button>
          <Button startIcon={<JamieLogo style={{ height: 16, width: 22 }} />} color="white" variant={window.location.pathname.startsWith("/admin/jamie") ? "contained" : undefined} sx={{ justifyContent: "start", background: window.location.pathname.startsWith("/admin/jamie") ? `linear-gradient(-72.47deg,rgb(255,145,0) 22.16%,rgba(255,145,0,.7) 88.47%)!important` : undefined, color: "white.main", borderRadius: 40, pl: 8, ml: -6 }} size="large" onClick={() => { navigate("/admin/jamie") }}>Jamie Prototype</Button>
          <Button startIcon={<JamieLogo style={{ height: 16, width: 22 }} />} color="white" variant={window.location.pathname.startsWith("/admin/jadmin") ? "contained" : undefined} sx={{ justifyContent: "start", background: window.location.pathname.startsWith("/admin/jadmin") ? `linear-gradient(-72.47deg,rgb(255,145,0) 22.16%,rgba(255,145,0,.7) 88.47%)!important` : undefined, color: "white.main", borderRadius: 40, pl: 8, ml: -6 }} size="large" onClick={() => { navigate("/admin/jadmin") }}>Jamie Admin</Button>
          <Button startIcon={<Security sx={{ height: 16 }} />} color="white" variant={window.location.pathname.startsWith("/admin/reimbursements") ? "contained" : undefined} sx={{ justifyContent: "start", background: window.location.pathname.startsWith("/admin/reimbursements") ? `linear-gradient(-72.47deg,rgb(255,145,0) 22.16%,rgba(255,145,0,.7) 88.47%)!important` : undefined, color: "white.main", borderRadius: 40, pl: 8, ml: -6 }} size="large" onClick={() => { navigate("/admin/reimbursements") }}>Reimbursements</Button>
          {whenEnrollmentFlag && <Button startIcon={<ListAlt sx={{ height: 16 }} />} color="white" variant={window.location.pathname.startsWith("/admin/applications") ? "contained" : undefined} sx={{ justifyContent: "start", background: window.location.pathname.startsWith("/admin/applications") ? `linear-gradient(-72.47deg,rgb(255,145,0) 22.16%,rgba(255,145,0,.7) 88.47%)!important` : undefined, color: "white.main", borderRadius: 40, pl: 8, ml: -6 }} size="large" onClick={() => { navigate("/admin/applications") }}>Applications</Button>}
          <Button startIcon={<Work sx={{ height: 16 }} />} color="white" variant={window.location.pathname.startsWith("/admin/cases") ? "contained" : undefined} sx={{ justifyContent: "start", background: window.location.pathname.startsWith("/admin/cases") ? `linear-gradient(-72.47deg,rgb(255,145,0) 22.16%,rgba(255,145,0,.7) 88.47%)!important` : undefined, color: "white.main", borderRadius: 40, pl: 8, ml: -6 }} size="large" onClick={() => { navigate("/admin/cases") }}>Cases</Button>
          <Button startIcon={<Storefront sx={{ height: 16 }} />} color="white" variant={window.location.pathname.startsWith("/admin/serviceMarketPlace") ? "contained" : undefined} sx={{ justifyContent: "start", background: window.location.pathname.startsWith("/admin/serviceMarketPlace") ? `linear-gradient(-72.47deg,rgb(255,145,0) 22.16%,rgba(255,145,0,.7) 88.47%)!important` : undefined, color: "white.main", borderRadius: 40, pl: 8, ml: -6 }} size="large" onClick={() => { navigate("/admin/serviceMarketPlace") }}>Services Marketplace</Button>
          <Button startIcon={<VerifiedUser sx={{ height: 16 }} />} color="white" variant={window.location.pathname.startsWith("/admin/adminusers") ? "contained" : undefined} sx={{ justifyContent: "start", background: window.location.pathname.startsWith("/admin/adminusers") ? `linear-gradient(-72.47deg,rgb(255,145,0) 22.16%,rgba(255,145,0,.7) 88.47%)!important` : undefined, color: "white.main", borderRadius: 40, pl: 8, ml: -6 }} size="large" onClick={() => { navigate("/admin/adminusers") }}>Admin Users</Button>
          <Button startIcon={<IntegrationInstructions sx={{ height: 16 }} />} color="white" variant={window.location.pathname.startsWith("/admin/integrations") ? "contained" : undefined} sx={{ justifyContent: "start", background: window.location.pathname.startsWith("/admin/integrations") ? `linear-gradient(-72.47deg,rgb(255,145,0) 22.16%,rgba(255,145,0,.7) 88.47%)!important` : undefined, color: "white.main", borderRadius: 40, pl: 8, ml: -6 }} size="large" onClick={() => { navigate("/admin/integrations") }}>Hris Integrations</Button>
          <Button startIcon={<ManageHistory sx={{ height: 16 }} />} color="white" variant={window.location.pathname.startsWith("/admin/data-pipelines") ? "contained" : undefined} sx={{ justifyContent: "start", background: window.location.pathname.startsWith("/admin/data-pipelines") ? `linear-gradient(-72.47deg,rgb(255,145,0) 22.16%,rgba(255,145,0,.7) 88.47%)!important` : undefined, color: "white.main", borderRadius: 40, pl: 8, ml: -6 }} size="large" onClick={() => { navigate("/admin/data-pipelines") }}>Data Pipelines</Button>

        </Box>
      </Drawer>
      <Box sx={{
        minHeight: "100vh",
        width: { sm: `calc(100% - ${drawerWidth})`, xs: "100%" },
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        // ml: drawerWidth
      }}
      >
        <AdminNavBar sx={{
          boxShadow: "0 4px 10px -4px rgba(0, 0, 0, 0.05),0 4px 8px -4px rgba(0, 0, 0, 0.05),0 4px 8px -4px rgba(0, 0, 0, 0.05)",
          backgroundColor: "background.default",
        }}></AdminNavBar>
        <Box sx={{
          p: 4,
        }}>
          <Suspense fallback={<Typography>Loading...</Typography>}>
            <Routing />
          </Suspense>
          <footer>
          </footer>
          {debug &&
            <Box sx={{
              pt: 8
            }}>
              <Debug />
            </Box>
          }
        </Box>
      </Box>
      <Modal
        open={modalState.login}
        onClose={() => modalDispatch({ type: "login" })}

        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Paper sx={{
          backgroundColor: "white.main",
          position: "absolute",
          top: "50%",
          left: "50%",
          minWidth: "300px",
          width: { xs: "100vw", sm: "auto" },
          height: { xs: "100vh", sm: "auto" },
          transform: "translate(-50%, -50%)",
          padding: "20px",
          overflowY: "scroll"
        }}>
          <IconButton onClick={() => modalDispatch({ type: "login" })} sx={{
            position: "absolute",
            top: 0,
            right: 0
          }}>
            <CloseOutlined></CloseOutlined>
          </IconButton>
          <LoginForm></LoginForm>
        </Paper>
      </Modal>
      <Dialog
        open={modalState.createAccount}
        onClose={() => { modalDispatch({ type: "createAccount" }) }}
      >
        <IconButton onClick={() => modalDispatch({ type: "createAccount" })} sx={{
          position: "absolute",
          top: "1%",
          right: "1%"
        }}></IconButton>
        <CreateAccount></CreateAccount>
      </Dialog>

    </Box >
  );
}
export default CompanyContent;
